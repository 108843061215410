import React, { useState, useEffect, useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Download, UploadCloud, XCircle } from 'lucide-react';
import ReactGA from "react-ga4";

export default function EnhancedQRCodeGenerator() {
  const [url, setUrl] = useState('https://yourwebsite.com');
  const [fgColor, setFgColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [borderRadius, setBorderRadius] = useState(0);
  const [shape, setShape] = useState('dots');
  const [eyeShape, setEyeShape] = useState('rounded');
  const [logoImage, setLogoImage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-4BYC845VQK"
      },
    ]);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Qr Code Generator" })
  }, [])

  // Define eye shape options with eye patterns
  const eyeShapeOptions = [
    {
      name: 'Square',
      value: 'square',
      eyeRadius: undefined,
      svg: (
        <svg width="50" height="50">
          <rect x="10" y="10" width="30" height="30" fill={fgColor} />
          <rect x="15" y="15" width="20" height="20" fill={bgColor} />
        </svg>
      ),
    },
    {
      name: 'Circle',
      value: 'circle',
      eyeRadius: [
        { outer: [10, 10, 10, 10], inner: [0, 0, 0, 0] },
        { outer: [10, 10, 10, 10], inner: [0, 0, 0, 0] },
        { outer: [10, 10, 10, 10], inner: [0, 0, 0, 0] },
      ],
      svg: (
        <svg width="50" height="50">
          <circle cx="25" cy="25" r="15" fill={fgColor} />
          <circle cx="25" cy="25" r="10" fill={bgColor} />
        </svg>
      ),
    },
    {
      name: 'Rounded',
      value: 'rounded',
      eyeRadius: [
        { outer: [15, 15, 15, 15], inner: [0, 0, 0, 0] },
        { outer: [15, 15, 15, 15], inner: [0, 0, 0, 0] },
        { outer: [15, 15, 15, 15], inner: [0, 0, 0, 0] },
      ],
      svg: (
        <svg width="50" height="50">
          <rect x="10" y="10" width="30" height="30" rx="8" ry="8" fill={fgColor} />
          <rect x="15" y="15" width="20" height="20" rx="5" ry="5" fill={bgColor} />
        </svg>
      ),
    },
  ];

  // Define QR code shape options with SVGs
  const qrShapeOptions = [
    {
      name: 'Square',
      value: 'square',
      qrStyle: 'squares',
      svg: (
        <svg width="50" height="50">
          <rect x="5" y="5" width="40" height="40" fill={fgColor} />
          <rect x="10" y="10" width="30" height="30" fill={bgColor} />
        </svg>
      ),
    },
    {
      name: 'Dots',
      value: 'dots',
      qrStyle: 'dots',
      svg: (
        <svg width="50" height="50">
          <circle cx="25" cy="25" r="20" fill={fgColor} />
          <circle cx="25" cy="25" r="15" fill={bgColor} />
        </svg>
      ),
    },
  ];

  // Find the selected eye shape and QR code shape objects
  const selectedEyeShape = eyeShapeOptions.find((option) => option.value === eyeShape);
  const selectedQRShape = qrShapeOptions.find((option) => option.value === shape);

  // Function to handle downloading the QR code
  const downloadQRCode = () => {
    const canvas = document.getElementById('qr-code');
    if (canvas) {
      try {
        const pngUrl = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (e) {
        alert('Failed to download QR code. Please try again.');
        console.error(e);
      }
    }
  };

  // Function to handle file selection
  const handleFile = (file) => {
    setError('');
    if (!file.type.startsWith('image/')) {
      setError('Please upload a valid image file.');
      return;
    }
    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      setError('File size exceeds 5MB.');
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setLogoImage(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  // Handlers for drag and drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleFile(files[0]);
      e.dataTransfer.clearData();
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center p-4">
      <div className="bg-white shadow-2xl p-8 w-full max-w-7xl flex flex-col md:flex-row gap-8 rounded-md">
        {/* Left Column - Controls */}
        <div className="w-full md:w-1/2 space-y-6">
          <h1 className="text-3xl font-bold text-center text-gray-800">
            QR Code Generator
          </h1>
          <div className="space-y-4">
            {/* URL Input */}
            <div>
              <label htmlFor="url" className="text-sm font-medium text-gray-700">
                Enter URL
              </label>
              <input
                type="text"
                id="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="https://example.com"
                className="mt-1 w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              />
            </div>
            {/* Foreground Color Picker */}
            <div>
              <label htmlFor="fgColor" className="text-sm font-medium text-gray-700">
                QR Code Color
              </label>
              <div className="mt-1 flex items-center space-x-2">
                <input
                  type="color"
                  id="fgColor"
                  value={fgColor}
                  onChange={(e) => setFgColor(e.target.value)}
                  className="w-12 h-12 p-1 rounded-md"
                />
                <span className="text-sm text-gray-500">{fgColor}</span>
              </div>
            </div>
            {/* Background Color Picker */}
            <div>
              <label htmlFor="bgColor" className="text-sm font-medium text-gray-700">
                Background Color
              </label>
              <div className="mt-1 flex items-center space-x-2">
                <input
                  type="color"
                  id="bgColor"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                  className="w-12 h-12 p-1 rounded-md"
                />
                <span className="text-sm text-gray-500">{bgColor}</span>
              </div>
            </div>
            {/* Border Radius Slider */}
            <div>
              <label
                htmlFor="borderRadius"
                className="text-sm font-medium text-gray-700"
              >
                Border Radius
              </label>
              <input
                type="range"
                id="borderRadius"
                min="0"
                max="50"
                value={borderRadius}
                onChange={(e) => setBorderRadius(Number(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <span className="text-sm text-gray-500">{borderRadius}px</span>
            </div>
            {/* QR Code Shape Selector with SVGs */}
            <div>
              <label className="text-sm font-medium text-gray-700">
                QR Code Shape
              </label>
              <div className="mt-1 flex space-x-4">
                {qrShapeOptions.map((option) => (
                  <button
                    key={option.value}
                    onClick={() => setShape(option.value)}
                    className={`p-2 border ${
                      shape === option.value ? 'border-blue-500' : 'border-gray-300'
                    } rounded hover:border-blue-400 transition`}
                  >
                    {option.svg}
                    <span className="block text-xs mt-1">{option.name}</span>
                  </button>
                ))}
              </div>
            </div>
            {/* Eye Shape Selector with SVGs */}
            <div>
              <label className="text-sm font-medium text-gray-700">Eye Shape</label>
              <div className="mt-1 flex space-x-4">
                {eyeShapeOptions.map((option) => (
                  <button
                    key={option.value}
                    onClick={() => setEyeShape(option.value)}
                    className={`p-2 border ${
                      eyeShape === option.value ? 'border-blue-500' : 'border-gray-300'
                    } rounded hover:border-blue-400 transition`}
                  >
                    {option.svg}
                    <span className="block text-xs mt-1">{option.name}</span>
                  </button>
                ))}
              </div>
            </div>
            {/* Logo Image Upload */}
            <div>
              <label className="text-sm font-medium text-gray-700">
                Upload Logo
              </label>
              <div
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={`mt-2 flex flex-col items-center justify-center border-2 ${
                  isDragging ? 'border-blue-500' : 'border-gray-300'
                } border-dashed rounded-md p-4 transition-colors duration-200 ${
                  isDragging ? 'bg-blue-50' : 'bg-gray-50'
                }`}
              >
                {logoImage ? (
                  <div className="relative">
                    <img
                      src={logoImage}
                      alt="Logo Preview"
                      className="w-24 h-24 object-contain rounded-md"
                    />
                    <button
                      onClick={() => setLogoImage(null)}
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 focus:outline-none"
                    >
                      <XCircle className="w-4 h-4" />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <UploadCloud className="w-8 h-8 text-gray-400" />
                    <p className="mt-2 text-sm text-gray-600">
                      Drag & Drop your logo here
                    </p>
                    <p className="text-sm text-gray-600">or</p>
                    <button
                      type="button"
                      onClick={() => fileInputRef.current.click()}
                      className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Browse Files
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          handleFile(e.target.files[0]);
                        }
                      }}
                      className="hidden"
                    />
                  </div>
                )}
              </div>
              {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
            </div>
          </div>
        </div>

        {/* Right Column - QR Code Display */}
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
          {/* QR Code Container */}
          <div
            className="p-4 bg-gray-100"
            style={{ borderRadius: `${borderRadius}px` }}
          >
            <QRCode
              id="qr-code"
              value={url}
              size={300}
              bgColor={bgColor}
              fgColor={fgColor}
              ecLevel="H" // High error correction
              includeMargin={true}
              qrStyle={selectedQRShape.qrStyle}
              eyeRadius={selectedEyeShape.eyeRadius}
              logoImage={logoImage}
              logoWidth={80} // Adjusted logo size
              logoHeight={80}
              removeQrCodeBehindLogo={true} // Ensures logo doesn't cover dots
              logoOpacity={1}
              logoPadding={10} // Adds padding around the logo
              logoPaddingStyle="circle" // Makes padding shape circular
              logoPaddingColor={bgColor} // Sets padding color to match background
              logoCrossOrigin="anonymous"
            />
          </div>
          {/* Download Button Under QR Code */}
          <button
            onClick={downloadQRCode}
            className="mt-4 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <Download className="w-4 h-4 mr-2" />
            Download QR Code
          </button>
        </div>
      </div>
    </div>
  );
}
