import React from 'react';
import EnhancedQRCodeGenerator from './EnhancedQRCodeGenerator.js';

function App() {
  return (
    <div className="App">
      <EnhancedQRCodeGenerator />
    </div>
  );
}

export default App;